

import { Map, GeolocateControl, Marker } from '../../node_modules/maplibre-gl/dist/maplibre-gl';

var map = null;
var first_update = true;
var last_t = null;
var has_unseen_elements = false;
var path = [];
var filtered_paths = [];
var API = "https://api.pirotech.fr";

var marker_icon = require("../assets/marker.png");
var user_marker = undefined
var my_marker = undefined;

var my_location = null;

var follow_user = true;
var follow_me = false;

function date_to_JJMMYYYY_HHMMSS(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    let str = "";
    str += (day < 10 ? "0" : "") + day + "/";
    str += (month < 10 ? "0" : "") + month + "/";
    str += year + " ";
    str += (hours < 10 ? "0" : "") + hours + ":";
    str += (minutes < 10 ? "0" : "") + minutes + ":";
    str += (seconds < 10 ? "0" : "") + seconds;

    return str;
}

function read_get_params() {
    let get_params = {};
    let get_params_str = window.location.search.substr(1);
    let get_params_arr = get_params_str.split("&");
    for (let i = 0; i < get_params_arr.length; i++) {
        let get_param = get_params_arr[i];
        let get_param_arr = get_param.split("=");
        get_params[get_param_arr[0]] = get_param_arr[1];
    }
    return get_params;
}

async function get_tracking_data(name, pass) {
    // post request to get_trajectory/<name>
    let url = API+"/get_trajectory/" + name+"?pass="+pass;
    if (last_t != null) {
        url += "&min_t=" + last_t;
    }
    const responce = await fetch(url, {
        method: "GET",
    })

    let data = await responce.json();
    return data;
}


function update_map_trace(name, pass){
    get_tracking_data(name, pass).then((data) => {
        if (data["start"] == undefined) return;

        let start = data["start"];
        data = data["data"];
        last_t = data[data.length - 1][2] + start;


        let date = new Date(last_t * 1000);
        document.getElementById("lastupdate").innerText = date_to_JJMMYYYY_HHMMSS(date);
        let lat = data[data.length - 1][0];
        let lon = data[data.length - 1][1];
        lat = Math.round(lat * 10000) / 10000;
        lon = Math.round(lon * 10000) / 10000;
        // if there is less than 4 decimal, add 0
        lat = lat.toString().split(".");
        lon = lon.toString().split(".");
        lat[1] = lat[1].padEnd(4, "0");
        lon[1] = lon[1].padEnd(4, "0");
        lat = lat.join(".");
        lon = lon.join(".");

        document.getElementById("coordinates").innerText = lat + ", " + lon;
        document.getElementById("goto").href = "https://www.google.fr/maps/dir//" + data[data.length - 1][0] + "," + data[data.length - 1][1];

        user_marker.setLngLat([data[data.length - 1][1], data[data.length - 1][0]]);


        if(path.length > 0
            && data[data.length-1][0] == path[path.length - 1][0]
            && data[data.length-1][1] == path[path.length - 1][1]) return;

        has_unseen_elements = true;

        for (let i = 0; i < data.length; i++) {
            let point = data[i];
            path.push([point[1], point[0], point[2] + start]);
        }
        filter_path();

        document.getElementById("speed").innerText = compute_mean_speed(1 * 60).toString();
        document.getElementById("dist").innerText = compute_dist().toString();


        var features = [];
        for (let i = 0; i < filtered_paths.length; i++){
            let path = filtered_paths[i];
            let feature = {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                    'type': 'LineString',
                    'coordinates': path
                }
            };
            features.push(feature);
        }
        // make a line on the map
        map.getSource("trace").setData({
            'type': 'FeatureCollection',
            'features': features.slice(0, features.length - 1)
        });
        map.getSource("last-trace").setData({
            'type': 'FeatureCollection',
            'features': features.slice(features.length - 1)
        });


        if (first_update) {
            map.flyTo({
                center: path[path.length - 1],
                zoom: 17,
                speed: 4,
                curve: 1,
            });
            first_update = false;
            has_unseen_elements = false;
        }
        else if (follow_user){
            map.flyTo({
                center: path[path.length - 1],
                zoom: 17,
                speed: 4,
                curve: 1,
            });
        }
    });

}


document.addEventListener("DOMContentLoaded", () => {
    console.log("DOM fully loaded and parsed");

    update_follow_btn_display();

    let params = read_get_params();
    let name = params["name"];
    let pass = params["pass"];

    let displayname = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    let els = document.getElementsByClassName("username")
    for (let i = 0; i < els.length; i++) {
        els[i].innerText = displayname;
    }





    map = new Map({
        container: 'map',
        style: 'https://tiles-api.maps.komoot.net/v1/style.json'
    });



    // superpose another tile layer
    map.once("load", () => {

        map.addSource("trace", {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': []
            }
        });
        map.addSource("last-trace", {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': []
            }
        });

        map.addLayer({
            "id": "trace",
            "type": "line",
            "source": "trace",
            "layout": {
                "line-join": "round",
                "line-cap": "round"
            },
            "paint": {
                "line-color": "#999999",
                "line-width": 5
            }
        });
        map.addLayer({
            "id": "last-trace",
            "type": "line",
            "source": "last-trace",
            "layout": {
                "line-join": "round",
                "line-cap": "round"
            },
            "paint": {
                "line-color": "#1e83d6bb",
                "line-width": 5
            }
        });

        // display my location with a blue dot
        map.addSource("my-location", {
            "type": "geojson",
            "data": {
                "type": "Feature",
                "properties": {},
                "geometry": {
                    "type": "Point",
                    "coordinates": [47.5848544, 6.7047168]
                }
            }
        });

        if ("geolocation" in navigator) {
            get_user_location();
        }




        user_marker = new Marker({
            element: document.getElementById("marker"),
            anchor: "bottom"
        });
        user_marker.setOffset([0, 10]);
        user_marker.setLngLat([0, 90]);
        user_marker.addTo(map);

        my_marker = new Marker({
            element: document.getElementById("my-marker"),
            anchor: "bottom"
        });
        my_marker.setOffset([0, 10]);
        my_marker.setLngLat([0, 90]);
        my_marker.addTo(map);


        setTimeout(() => {
            update_map_trace(name, pass);
        }, 200);
        setInterval(() => {
            update_map_trace(name, pass);
        }, 10000);

        document.getElementById("map-loading").style.display = "none";

        resize_map();



        // on map moved by user
        document.getElementById("map").addEventListener("mousedown", mouse_down);
        document.getElementById("map").addEventListener("touchstart", mouse_down);

        document.getElementById("map").addEventListener("mousemove", mouse_move);
        document.getElementById("map").addEventListener("touchmove", mouse_move);

        document.getElementById("map").addEventListener("mouseup", mouse_up);
        document.getElementById("map").addEventListener("touchend", mouse_up);

        document.getElementById("go-to-me").addEventListener("click", toggle_follow_me);
        document.getElementById("go-to-user").addEventListener("click", toggle_follow_user);


    });

});

window.addEventListener("resize", () => {
    resize_map();
    map.resize();
});

window.addEventListener("focus", function(){
    if (filtered_paths.length == 0) return;
    if (!has_unseen_elements) return;

    has_unseen_elements = false;

    setTimeout(() => {
        follow_user = true;
        update_follow_btn_display();


        map.flyTo({
            center: filtered_paths[filtered_paths.length - 1],
            zoom: 17,
            speed: 4,
            curve: 1,
        });
    }, 100);
})


function resize_map() {

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;

    let header_height = document.getElementById("header").offsetHeight;
    let footer_height = document.getElementById("footer").offsetHeight;
    window_height -= header_height + footer_height;

    document.getElementById("map-container").style.height = window_height + "px";

    let map = document.getElementById("map");

    if (window_width < 800) {
        let min_side = 800;
        let scale = Math.min(window_width / min_side, window_height / min_side);
        let w = window_width / scale;
        let h = window_height / scale;
        map.style.transform = "scale(" + scale + ")";
        map.style.width = w + "px";
        map.style.height = h + "px";

    }
    else {
        map.style.transform = "scale(1)";
        map.style.width = window_width + "px";
        map.style.height = window_height + "px";
    }
}


function get_user_location() {
    navigator.geolocation.watchPosition((position) => {
        let lat = position.coords.latitude;
        let lon = position.coords.longitude;
        my_location = [lon, lat];
        my_marker.setLngLat([my_location[0], my_location[1]]);


        if (follow_me) {
            map.flyTo({
                center: my_location,
                zoom: 17,
                speed: 4,
                curve: 1,
            });
        }
    });
}





var mouse_pressed = false;
function mouse_down(){
    mouse_pressed = true;
}

function mouse_move(){
    if (mouse_pressed){
        map_moved();
    }
}
function mouse_up(){
    mouse_pressed = false;
}



function map_moved(){
    follow_user = false;
    follow_me = false;
    update_follow_btn_display();
}

function toggle_follow_user(){
    follow_user = !follow_user;
    follow_me = false;
    update_follow_btn_display();

    if (follow_user){
        map.flyTo({
            center: path[path.length - 1],
            zoom: 17,
            speed: 4,
            curve: 1,
        });
    }
}

function toggle_follow_me(){
    follow_me = !follow_me;
    follow_user = false;
    update_follow_btn_display();

    if (follow_me){
        map.flyTo({
            center: my_location,
            zoom: 17,
            speed: 4,
            curve: 1,
        });
    }
}


function update_follow_btn_display(){
    if (follow_me) document.getElementById("go-to-me").className = "btn on";
    else document.getElementById("go-to-me").className = "btn off";
    if (follow_user) document.getElementById("go-to-user").className = "btn on";
    else document.getElementById("go-to-user").className = "btn off";
}

function lat_lon_distance_m(lat1, lon1, lat2, lon2){
    let R = 6371e3; // metres
    let φ1 = lat1 * Math.PI/180; // φ, λ in radians
    let φ2 = lat2 * Math.PI/180;
    let Δφ = (lat2-lat1) * Math.PI/180;
    let Δλ = (lon2-lon1) * Math.PI/180;

    let a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ/2) * Math.sin(Δλ/2);

    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    return R * c; // in metres
}

function compute_mean_speed(delay){

    if (path.length < 2) return 0;


    let last_t = path[path.length - 1][2];
    let first_t = last_t - delay;

    // indice of the first point > first_t
    let first_i = 0;
    while (path[first_i][2] < first_t) first_i++;

    let start_t = path[first_i][2];



    let total_distance = 0;
    for (let i = first_i+1; i < path.length; i++){
        total_distance += lat_lon_distance_m(path[i-1][1], path[i-1][0], path[i][1], path[i][0]);
    }
    let total_time = last_t - start_t;
    if (total_time == 0) return 0;

    let speed = total_distance / total_time * 3.6; // in m/s
    if (speed < 3) return 0;

    return Math.round(speed * 10) / 10;
}

function compute_dist(){
    let traj = filtered_paths[filtered_paths.length - 1];
    let total_distance = 0;
    for (let i = 1; i < traj.length; i++){
        total_distance += lat_lon_distance_m(traj[i-1][1], traj[i-1][0], traj[i][1], traj[i][0]) / 1000;
    }
    return Math.round(total_distance*10)/10;
}


function bearing(lat1, lon1, lat2, lon2){
    const φ1 = lat1 * Math.PI/180;
    const φ2 = lat2 * Math.PI/180;
    const λ1 = lon1 * Math.PI/180;
    const λ2 = lon2 * Math.PI/180;

    const y = Math.sin(λ2-λ1) * Math.cos(φ2);
    const x = Math.cos(φ1)*Math.sin(φ2) -
            Math.sin(φ1)*Math.cos(φ2)*Math.cos(λ2-λ1);
    const θ = Math.atan2(y, x);
    const brng = (θ*180/Math.PI + 360) % 360; // in degrees
    return brng;
}

function angle_diff(a1, a2){
    let diff = Math.abs(a1 - a2) % 360;
    let result = diff > 180 ? 360 - diff : diff;
    return result;
}

function check_pts(zone_i, t){
    if (t - zone_i >= 5 && lat_lon_distance_m(path[zone_i][1], path[zone_i][0], path[t][1], path[t][0]) < 50) return true;

    let max_dist = 0;
    for (let s=t;s<t+20&&s<path.length;s++){
        let d = lat_lon_distance_m(path[zone_i][1], path[zone_i][0], path[s][1], path[s][0]);
        if (d > max_dist) max_dist = d;

        if (d < 30) return true;
    }
    console.log(max_dist);


    return false;
}


function filter_path(){

    filtered_paths = [path]

    let paths = [];

    for (let i = 0; i < path.length; i++){
        let point = [path[i][0], path[i][1]];
        if (i == 0 || lat_lon_distance_m(paths[paths.length-1][paths[paths.length-1].length-1][1], paths[paths.length-1][paths[paths.length-1].length-1][0], point[1], point[0]) > 500){
            paths.push([]);
        }
        paths[paths.length-1].push(point);
    }
    filtered_paths = paths

    console.log(filtered_paths);

}